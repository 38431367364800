import React, {Component} from 'react';
import FestivalListController, {Props} from "./FestivalListController";
import NavBarWeb from "../../../components/src/NavBar.web";
import {Container, Box, Typography, Button, Grid, TextField, Divider, CircularProgress} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import NativeSelect from '@material-ui/core/NativeSelect';
import SearchIcon from '@material-ui/icons/Search';
import Footer from "../../../components/src/AppFooter.web";
import {withStyles} from "@material-ui/core/styles";
import {purple} from "@material-ui/core/colors";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Loader from "../../../components/src/Loader.web";
import {withRouter,Link} from "react-router-dom"
import moment from 'moment'

class FestivalList extends FestivalListController {
    render() {
        return (
            <>
                <NavBarWeb/>
                <Container>
                    <Grid container style={{margin:"20px"}}>
                        <Grid item xs={12}>
                            <Box style={{display:'flex',alignItems:"center",justifyContent:'space-between'}}>
                                <TextField
                                    variant="outlined"
                                    style={{width:"80%"}}
                                    onChange={this.handleSearchKey}
                                    value={this.state.searchKey}
                                    InputProps={{
                                        startAdornment:<SearchIcon/>
                                    }}
                                />
                                <ColorButton variant="outlined" onClick={this.handleSearchClick}>
                                    Search
                                </ColorButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Box style={{backgroundColor: "#ebeced",paddingBottom:"50px",paddingTop:"30px",minHeight:"75vh"}}>
                    <Container>
                        <Grid container>
                            <Grid xs={12} sm={6} style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <Typography variant="body2" style={{fontWeight:'bold'}}>Filter By:</Typography>
                                <NativeSelect name="short" onChange={this.handelFilter}>
                                    <option value="upcoming_festivals">Upcoming Festival</option>
                                    <option value="old_festivals">Old Festival</option>
                                </NativeSelect>
                            </Grid>
                            <Grid xs={12} sm={6} style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
                                <Link to="/EventRegistration/add/" style={{textDecoration:'none',border:'1px purple solid',borderRadius:"5px"}}>
                                    <ColorButton>Add Festival</ColorButton>
                                </Link>
                            </Grid>
                            <Divider></Divider>
                            <Grid xs={12} style={{marginTop:'50px'}}>
                                {
                                    this.state.isLoading ?
                                        <Grid xs={12} style={{height:"50vh",display:'flex',alignItems:'center',justifyContent:"center"}}>
                                            <CircularProgress />
                                        </Grid> :
                                        <Grid container spacing={2}>
                                            {
                                                this.state.festivalDetails?.map((item:any,key)=>{
                                                    return(
                                                        <Grid xs={12} sm={6} md={4} key={key}>
                                                            <Card style={{maxWidth: "95%",marginBottom:"20px"}}>
                                                                <CardActionArea onClick={()=>this.manageRedirect(item.id)} >
                                                                    <CardMedia
                                                                        style={{height:200}}
                                                                        // @ts-ignore
                                                                        image={item?.attributes?.image_file}
                                                                        title="Contemplative Reptile"
                                                                    />
                                                                    <CardContent>
                                                                        <Typography style={{height:"35px",overflow:"hidden"}} gutterBottom variant="body1" component="h2">
                                                                            {item?.attributes?.title}
                                                                        </Typography>
                                                                        <Typography gutterBottom variant="subtitle2" color="inherit">{moment(item.attributes?.deadline?.event_start_date).format("MMMM DD, YYYY")}</Typography>
                                                                        <Box style={{height:"40px",overflow:"hidden"}}>
                                                                            <Typography variant="body2" color="textSecondary" component="p">
                                                                                {item?.attributes?.event_type}
                                                                            </Typography>
                                                                        </Box>
                                                                    </CardContent>
                                                                    <Divider></Divider>
                                                                </CardActionArea>
                                                                <CardActions style={{display:'flex',justifyContent:"center",marginBottom:"20px",paddingTop:"20px"}}>
                                                                    <Button onClick={()=>this.manageRedirect(item.id)} variant="outlined" size="large" color="primary" style={{borderRadius: "25px"}}>
                                                                        View Festival
                                                                    </Button>
                                                                </CardActions>
                                                            </Card>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                }
                            </Grid>
                            <Grid xs={12} style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:'35px'}}>
                                {/*@ts-ignore*/}
                                <Pagination count={this.state.pagination?.total_pages || 0} page={this.state.currentPage} onChange={this.handlePagination} />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                <Footer />
            </>
        );
    }
}

const ColorButton = withStyles((theme) => ({
    root: {
        color: 'black',
        fontWeight:'bold',
        borderColor: purple[500],
        height:"50px",
        width:"150px",
        '&:hover': {
            borderColor: purple[700],
            color: 'black',
            fontWeight:'bold',
        },
    },
}))(Button);

export default (...props:any) => {
    // @ts-ignore
    const WithRouteAppHeader = withRouter(FestivalList)
    return (
        <WithRouteAppHeader {...props} />
    )
};