// Customizable Area Start
import React, { useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Stepper,
  Step,
  StepLabel,
  Toolbar,
  AppBar,
  Tab,
  Tabs,
  withStyles
} from "@material-ui/core";
import EventDetails from "./EventDetails.web";
import DatesDeadlines from "./DatesDeadlines.web";
import CategoryEntryFees from "./CategoryEntryFees.web";
import ContactVenueInfo from "./ContactVenuInfo.web";
import AdditionalSettings from "./AdditionalSettings.web";
import "./style.css";
import {withRouter} from "react-router-dom";
import EventRegistrationController, {
  Props
} from "./EventRegistrationController";
const steps = [1, 2, 3, 4, 5];


let customizeTabText: React.CSSProperties = {
  fontWeight: 800,
  textTransform: 'capitalize',
  color: "#000"
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 70,
      width: "100%",
      height: "20px",
      backgroundColor: "maroon"
    }
  }
})((props: any) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

class EditEvent extends EventRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // @ts-ignore
    const { activeStep, callFestivalAPI, loading } = this.state;

    return (
      <>
        <div className={loading && activeStep !== 4 ? 'mainEventContainer loading' : 'mainEventContainer notLoading'}>
        {/* <div className={'mainEventContainer'}>  */}
          <Box>
            <AppBar position="static" color="default">
              <Container style={{ maxWidth: "1400px" }} maxWidth={false}>
                <Toolbar disableGutters>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <StyledTabs
                      value={this.state.value}
                      onChange={this.handleChange}
                      textColor="primary"
                      indicatorColor="primary"
                      aria-label="primary tabs example"
                      centered={false}
                      className="editEvent">
                      <Tab label="View" {...a11yProps(0)} style={customizeTabText} />
                      <Tab label="Edit" {...a11yProps(1)} style={customizeTabText} />
                      <Tab label="Manage" {...a11yProps(2)} style={customizeTabText} />
                      <Tab label="Marketing" {...a11yProps(3)} style={customizeTabText} />
                    </StyledTabs>
                    <div>
                      <Button className="submissionsButton" onClick={this.handleSave} type="submit">SAVE</Button>
                      <Button disabled={activeStep !== 4} className={`${activeStep !== 4 ? "disabled listButton" : "listButton" }`} onClick={this.handleSave}>LIST EVENT</Button>
                    </div>
                  </Box>
                </Toolbar>
              </Container>
            </AppBar>
          </Box>

          <Box className="StepperCard">
            <Stepper activeStep={activeStep} style={{ width: '85%' }}>
              {steps.map((label, index) => {
                return (
                  <Step key={label} >
                    <StepLabel icon={'0' + `${index + 1}`}></StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          <Container
            style={{ maxWidth: "1400px", backgroundColor: "#F2F3F5" }}
            maxWidth={false}
          >
            {activeStep === 0 && <EventDetails handleNextStep={this.handleNextStep} callFestivalAPI={callFestivalAPI} handleLoadingTrue={this.handleLoadingTrue} handleLoadingFalse={this.handleLoadingFalse} loading={loading}/>}
            {activeStep === 1 && <ContactVenueInfo handleNextStep={this.handleNextStep} handleBack={this.handleBack} handleLoadingTrue={this.handleLoadingTrue} handleLoadingFalse={this.handleLoadingFalse} loading={loading} />}
            {activeStep === 2 && <DatesDeadlines handleNextStep={this.handleNextStep} handleBack={this.handleBack} handleLoadingTrue={this.handleLoadingTrue} handleLoadingFalse={this.handleLoadingFalse} loading={loading}/>}
            {activeStep === 3 && <CategoryEntryFees handleNextStep={this.handleNextStep} handleBack={this.handleBack} handleLoadingTrue={this.handleLoadingTrue} handleLoadingFalse={this.handleLoadingFalse} loading={loading}/>}
            {activeStep === 4 && <AdditionalSettings handleBack={this.handleBack}  />}
          </Container>
        </div>
      </>
    );
  }
}
export default (...props:any) => {
  // @ts-ignore
  const WithRouteAppHeader = withRouter(EditEvent)
  return (
      <WithRouteAppHeader {...props} />
  )
};
// Customizable Area End