import React, {Component} from 'react';
import BrowseFestivalController, {Props} from "./BrowseFestivalController";
import NavBarWeb from "../../../components/src/NavBar.web";
import {
    Container,
    Box,
    Typography,
    Button,
    Grid,
    TextField,
    Divider,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import NativeSelect from '@material-ui/core/NativeSelect';
import SearchIcon from '@material-ui/icons/Search';
import Footer from "../../../components/src/AppFooter.web";
import {withStyles} from "@material-ui/core/styles";
import {purple} from "@material-ui/core/colors";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Loader from "../../../components/src/Loader.web";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import {filterLogo} from './assets'
import {withRouter,Link} from "react-router-dom"
import moment from 'moment'

class FestivalList extends BrowseFestivalController {
    render() {
        return (
            <>
                <NavBarWeb/>
                <Container>
                    <Grid container style={{marginLeft:"-15px",marginTop:"10px",marginBottom:"10px",marginRight:"-24px"}}>
                        <Grid item xs={12}>
                            <Box style={{display:'flex',alignItems:"center"}}>
                                <TextField
                                    variant="outlined"
                                    style={{width:"81%",marginRight:"10px"}}
                                    onChange={this.handleSearchKey}
                                    value={this.state.searchKey}
                                    InputProps={{
                                        startAdornment:<SearchIcon/>
                                    }}
                                />
                                <ColorButton style={{marginRight:"10px"}} variant="outlined" onClick={this.handleSearchClick}>
                                    Search
                                </ColorButton>
                                <ColorFilledButton variant="contained" onClick={this.handleSearchClick}>
                                    <img height="30px" src={filterLogo}/>
                                </ColorFilledButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Box style={{backgroundColor: "#ebeced",paddingBottom:"50px",paddingTop:"30px"}}>
                    <Container>
                        <Grid container>
                            <Grid xs={12} sm={6} style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <Typography variant="body2" style={{fontWeight:'bold'}}>Short By:</Typography>
                                <NativeSelect disableUnderline name="short" onChange={this.handelFilter}>
                                    <option value="upcoming_festivals">Recently Added</option>
                                    <option value="old_festivals">Old Festival</option>
                                </NativeSelect>
                            </Grid>
                            <Grid xs={12} sm={6} style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
                                {/*@ts-ignore*/}
                                <Pagination count={this.state.pagination?.total_pages || 0} page={this.state.currentPage} onChange={this.handlePagination} />
                            </Grid>
                            <Grid xs={12} sm={6} style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
                                <Divider />
                            </Grid>

                            <Grid xs={12} style={{marginTop:'20px'}}>
                                {
                                    this.state.isLoading ?
                                        <Grid xs={12} style={{height:"65vh",display:'flex',alignItems:'center',justifyContent:"center"}}>
                                            <CircularProgress />
                                        </Grid>:
                                        <Grid container spacing={2}>
                                            {
                                                this.state.festivalDetails?.map((item:any,key)=>{
                                                    return(
                                                        <Grid xs={12} sm={6} md={4} key={key}>
                                                            <Card style={{maxWidth: "95%",marginBottom:"20px"}}>
                                                                <CardActionArea>
                                                                    <CardMedia
                                                                        style={{height:200}}
                                                                        // @ts-ignore
                                                                        image={item?.attributes?.image_file}
                                                                        title="Contemplative Reptile"
                                                                    />
                                                                    <CardContent>
                                                                        <Typography gutterBottom variant="h5" component="h2" style={{height:"35px",overflow:"hidden"}}>
                                                                            {item?.attributes?.title}
                                                                        </Typography>
                                                                        <Box style={{height:"40px",overflow:"hidden"}}>
                                                                            <Typography variant="body2" color="textSecondary" component="p">
                                                                                {item?.attributes?.event_type}
                                                                            </Typography>
                                                                        </Box>
                                                                    </CardContent>
                                                                    <Box style={{position:'absolute',top:'0',left:'5%',width:"93%"}}>
                                                                        <Box style={{width:"100%",display:'flex',flexDirection:"row",alignItems:'center',justifyContent:"space-between"}}>
                                                                            <Typography color="inherit">{moment(item.attributes?.deadline?.event_start_date).format("MMMM DD, YYYY")}</Typography>
                                                                            <IconButton><FavoriteBorderIcon/></IconButton>
                                                                        </Box>
                                                                    </Box>
                                                                    <Divider></Divider>
                                                                </CardActionArea>
                                                                <CardActions style={{display:'flex',justifyContent:"space-around",marginBottom:"20px",paddingTop:"20px"}}>
                                                                    <Button onClick={()=>this.manageRedirect(item.id)} variant="outlined" size="large" color="primary" style={{borderRadius: "25px",height:"40px"}}>
                                                                        View Festival
                                                                    </Button>
                                                                    <Button onClick={()=>this.manageRedirect(item.id)} variant="contained" size="large" color="primary" style={{borderRadius: "25px",height:"40px"}}>
                                                                        Submit Now
                                                                    </Button>
                                                                </CardActions>

                                                            </Card>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                <Footer />
            </>
        );
    }
}

const ColorButton = withStyles((theme) => ({
    root: {
        color: 'black',
        fontWeight:'bold',
        borderColor: purple[500],
        height:"50px",
        width:"150px",
        '&:hover': {
            borderColor: purple[700],
            color: 'black',
            fontWeight:'bold',
        },
    },
}))(Button);

const ColorFilledButton = withStyles((theme) => ({
    root: {
        backgroundColor: purple[500],
        fontWeight:'bold',
        borderColor: purple[500],
        height:"50px",
        width:"70px",
        '&:hover': {
            borderColor: purple[700],
            color: 'black',
            fontWeight:'bold',
        },
    },
}))(Button);

export default (...props:any) => {
    // @ts-ignore
    const WithRouteAppHeader = withRouter(FestivalList)
    return (
        <WithRouteAppHeader {...props} />
    )
};