// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  TextField,
  FormControlLabel,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  withStyles,
  Checkbox,
  Popover
} from "@material-ui/core";
import { Delete, DragIcon } from "./assets";
import { Country, State, City } from "country-state-city";
import "./style.css";
import ContactVenuInfoController, {
  Props
} from "./ContactVenuInfoController";
import { Formik, useFormikContext } from "formik";
import { stepTwoSchema, formInitialValues } from "./validationSchemas"
//ts-ignore
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Loader from "../../../components/src/Loader.web";

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles
  userSelect: "none",
  margin: `0 0 ${8}px 0`,

  // change background colour if dragging
  background: isDragging ? "rgba(166,32,160, 0.2)" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean, count: number) => ({
  background: isDraggingOver ? "rgba(166,32,160, 0.2)" : "white",
  minHeight: `${count * 100}px`,
  padding: 8,
  width: "200%",
});

export default class ContactVenuInfo extends ContactVenuInfoController {

  constructor(props: Props) {
    super(props);
  }

  onDragEnd(result : any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const customRow = reorder(
      this.state.customRow,
      result.source.index,
      result.destination.index
    );

    this.setState({
      customRow
    });
  }

  render() {
    // @ts-ignore
    const { allStates, allCities, customRow, festivalData, isReinitailize, anchorEl, venueId, openVenue, addBtnVisible, submissionData, venueStates, venueCities, seprateAddress, allSubStates, allSubCities } = this.state;
    let countries = Country.getAllCountries();
    const { loading } = this.props;
    const open = Boolean(anchorEl);

    return (
      <>
      {loading && <Loader loading={loading} /> }
      <Formik
        enableReinitialize={festivalData && isReinitailize}
        initialValues={{
          website: festivalData?.website || "",
          email: festivalData?.email || "",
          phone: festivalData?.phone || "",
          address: festivalData?.address || "",
          country: festivalData?.country || "",
          state: festivalData?.state || "",
          city: festivalData?.city || "",
          postal: festivalData?.postal_code || "",
          twitter: festivalData?.twitter || "",
          fb: festivalData?.facebook || "",
          insta: festivalData?.instagram || "",
          imdb: festivalData?.imdb || "",
          subAddress: submissionData?.address || "",
          subCountry: submissionData?.country || "",
          subState: submissionData?.state || "",
          subCity: submissionData?.city || "",
          subPostal: submissionData?.postal_code || ""
        }}
        validationSchema={stepTwoSchema}
        onSubmit={(values, actions) => {
          this.handleSubmitEvent(values, customRow)
          
        }}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
            <Box sx={{ padding: "50px 0px 0px" }} bgcolor="#f2f3f5">
              <Typography variant="h2" component="h2">
                Accept Submissions to Your Event
              </Typography>
            </Box>
            <Box sx={{ padding: "20px 0px" }}>
              <Box className="formTitle">
                <Typography variant="h4" component="h4">
                  Contact & Venue Information
                </Typography>
              </Box>
              <Box bgcolor="#FFF">
                <Box component="form" sx={{ padding: "20px 20px 150px" }}>
                  <div>
                    <Box border={0.2} borderColor="#f5f5f5" borderRadius={4}>
                      <FormControl style={{ width: "100%", padding: "20px 0px" }}>
                        <FormLabel component="legend" className="label">
                          Website{" "}<span className="astrick">*</span>
                        </FormLabel>
                        <TextField
                          id="standard-select-currency-native"
                          className="inputClass"
                          variant="standard"
                          name="website"
                          placeholder="https://"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.website}
                        >
                        </TextField>
                        {props.errors.website && props.touched.website ? (<p className="formError">{props.errors.website}</p>) : null}
                      </FormControl>

                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                          <FormLabel component="legend">Email{" "}<span className="astrick">*</span></FormLabel>
                          <TextField
                            name="email"
                            id="outlined-disabled"
                            placeholder="Enter your email id"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.email}
                            className="inputClass"
                          />
                          {props.errors.email && props.touched.email ? (<p className="formError">{props.errors.email}</p>) : null}
                        </FormControl>
                        <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                          <FormLabel component="legend">Phone No.</FormLabel>
                          <TextField
                            id="outlined-disabled"
                            placeholder="Eg: +91-999-768-867"
                            name="phone"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.phone}
                            className="inputClass"
                          />
                          {props.errors.phone && props.touched.phone ? (<p className="formError">{props.errors.phone}</p>) : null}
                        </FormControl>
                      </Box>

                      <Box sx={{ width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div style={{ padding: '20px 0px', width: '45%' }} >
                            <FormControl
                              component="div"
                              style={{ padding: "20px 0px", width: "100%" }}
                            >
                              <FormLabel component="legend">Address</FormLabel>
                              <TextField
                                placeholder="Enter your full Address"
                                id="outlined-disabled"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                className="inputClass"
                                name="address"
                                value={props.values.address}
                              />
                            </FormControl>
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-between", padding: '20px 0px', width: '45%' }}>
                            <FormControl
                              component="div"
                              style={{ padding: "20px 0px", width: "45%" }}
                            >
                              <FormLabel component="legend">Country of Origin{" "}<span className="astrick">*{" "}</span></FormLabel>
                              <TextField
                                id="standard-select-currency-native"
                                select
                                onChange={props.handleChange}
                                SelectProps={{
                                  native: true,
                                  classes: { icon: "icon" },
                                }}
                                InputProps={{ className: "root", onBlur: this.handleCountryBlur }}
                                variant="standard"
                                name="country"
                                value={props.values.country}
                              >
                                <option value={""}>Select from option</option>
                                {countries &&
                                  countries.map((cur: any) => (
                                    <option value={cur.isoCode} key={cur.isoCode}>{cur.name}</option>
                                  ))}
                              </TextField>
                              {props.errors.country && props.touched.country ? (<p className="formError">{props.errors.country}</p>) : null}
                            </FormControl>

                            <FormControl
                              component="div"
                              style={{ padding: "20px 0px", width: "45%" }}
                            >
                              <FormLabel component="legend">State / Province</FormLabel>
                              <TextField
                                id="standard-select-currency-native"
                                select
                                onChange={props.handleChange}
                                SelectProps={{
                                  native: true,
                                  classes: { icon: "icon" },
                                }}
                                InputProps={{ className: "root", onBlur: this.handleStateBlur }}
                                variant="standard"
                                name="state"
                                value={props.values.state}
                              >
                                <option value={""}>Select from option</option>
                                {allStates &&
                                  allStates.map((state: any) => (
                                    <option value={state.isoCode} key={state.isoCode}>{state.name}</option>
                                  ))}
                              </TextField>
                            </FormControl>
                          </div>
                        </div>
                      </Box>

                      <Box sx={{ width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Grid style={{ display: "flex", justifyContent: "space-between", padding: '20px 0px', width: '45%' }}>
                            <FormControl
                              component="div"
                              style={{ padding: "20px 0px", width: "45%" }}
                            >
                              <FormLabel component="legend">City</FormLabel>
                              <TextField
                                id="standard-select-currency-native"
                                select
                                onChange={props.handleChange}
                                SelectProps={{
                                  native: true,
                                  classes: { icon: "icon" },
                                }}
                                InputProps={{ className: "root" }}
                                variant="standard"
                                name="city"
                                value={props.values.city}
                              >
                                <option value={""}>Select from option</option>
                                {allCities &&
                                  allCities.map((city: any) => (
                                    <option value={city.name} key={city.name}>{city.name}</option>
                                  ))}
                              </TextField>
                            </FormControl>

                            <FormControl
                              component="div"
                              style={{ padding: "20px 0px", width: "45%" }}
                            >
                              <FormLabel component="legend">Postal Code</FormLabel>
                              <TextField
                                id="standard-select-role"
                                placeholder="Enter pin code"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                InputProps={{ className: "root" }}
                                name="postal"
                                value={props.values.postal}
                                variant="standard"
                              >
                                {props.errors.postal && props.touched.postal ? (<p className="formError">{props.errors.postal}</p>) : null}

                              </TextField>
                            </FormControl>
                          </Grid>
                          <div style={{ padding: '20px 0px', width: '45%' }}>
                            <FormControl
                              component="div"
                              style={{ padding: "20px 0px", width: "100%" }}
                            >
                              <FormLabel component="legend">Twitter</FormLabel>
                              <TextField
                                placeholder="twitter.com/xyz"
                                id="outlined-disabled"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                className="inputClass"
                                name="twitter"
                                value={props.values.twitter}
                              />
                            </FormControl>
                          </div>
                        </div>
                      </Box>

                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                          <FormLabel component="legend">Facebook</FormLabel>
                          <TextField
                            name="fb"
                            id="outlined-disabled"
                            placeholder="Facebook.com/xyz"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.fb}
                            className="inputClass"
                          />
                          {props.errors.fb && props.touched.fb ? (<p className="formError">{props.errors.fb}</p>) : null}
                        </FormControl>
                        <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                          <FormLabel component="legend">Instagram</FormLabel>
                          <TextField
                            id="outlined-disabled"
                            placeholder="Instagram.com/xyz"
                            name="insta"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.insta}
                            className="inputClass"
                          />
                          {props.errors.insta && props.touched.insta ? (<p className="formError">{props.errors.insta}</p>) : null}
                        </FormControl>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                          <FormLabel component="legend">IMDB</FormLabel>
                          <TextField
                            name="imdb"
                            id="outlined-disabled"
                            placeholder="https://www.imdb.com/"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.imdb}
                            className="inputClass"
                          />
                          {props.errors.imdb && props.touched.imdb ? (<p className="formError">{props.errors.imdb}</p>) : null}
                        </FormControl>
                      </Box>

                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                          <FormLabel component="legend">Submission Address{" "}<span className="astrick">*{" "}</span>
                            <span
                              aria-owns={open ? 'mouse-over-popover' : undefined}
                              aria-haspopup="true"
                              onMouseEnter={this.handlePopoverOpen}
                              onMouseLeave={this.handlePopoverClose}
                              className="labelLink"
                            >
                              What is this?
                            </span>
                            <Popover
                              id="mouse-over-popover"
                              className="popover"
                              style={{ pointerEvents: 'none' }}
                              open={open}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              onClose={this.handlePopoverClose}
                              disableRestoreFocus
                            >
                              <Typography style={{ padding: "10px" }}>Information about field</Typography>
                            </Popover>
                          </FormLabel>
                          <FormControlLabel
                            // value={Object.keys(item)[0]}
                            control={<CustomColorCheckbox />}
                            label={"My festival has a separate submission address."}
                            onChange={this.handleSelect}
                            checked={seprateAddress}
                          />
                        </FormControl>
                      </Box>

                      {seprateAddress && 
                      <>
                       <Box sx={{ width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div style={{ padding: '10px 0px', width: '45%' }} >
                            <FormControl
                              component="div"
                              style={{ padding: "10px 0px", width: "100%" }}
                            >
                              <FormLabel component="legend">Address</FormLabel>
                              <TextField
                                placeholder="Enter your full Address"
                                id="outlined-disabled"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                className="inputClass"
                                name="subAddress"
                                value={props.values.subAddress}
                              />
                            </FormControl>
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-between", padding: '10px 0px', width: '45%' }}>
                            <FormControl
                              component="div"
                              style={{ padding: "10px 0px", width: "45%" }}
                            >
                              <FormLabel component="legend">Country of Origin{" "}</FormLabel>
                              <TextField
                                id="standard-select-currency-native"
                                select
                                onChange={props.handleChange}
                                SelectProps={{
                                  native: true,
                                  classes: { icon: "icon" },
                                }}
                                InputProps={{ className: "root", onBlur: this.handleSubCountryBlur }}
                                variant="standard"
                                name="subCountry"
                                value={props.values.subCountry}
                              >
                                <option value={""}>Select from option</option>
                                {countries &&
                                  countries.map((cur: any) => (
                                    <option value={cur.isoCode} key={cur.isoCode}>{cur.name}</option>
                                  ))}
                              </TextField>
                            </FormControl>

                            <FormControl
                              component="div"
                              style={{ padding: "10px 0px", width: "45%" }}
                            >
                              <FormLabel component="legend">State / Province</FormLabel>
                              <TextField
                                id="standard-select-currency-native"
                                select
                                onChange={props.handleChange}
                                SelectProps={{
                                  native: true,
                                  classes: { icon: "icon" },
                                }}
                                InputProps={{ className: "root", onBlur: this.handleSubStateBlur }}
                                variant="standard"
                                name="subState"
                                value={props.values.subState}
                              >
                                <option value={""}>Select from option</option>
                                {allSubStates &&
                                  allSubStates.map((state: any) => (
                                    <option value={state.isoCode} key={state.isoCode}>{state.name}</option>
                                  ))}
                              </TextField>
                            </FormControl>
                          </div>
                        </div>
                      </Box>

                      <Box sx={{ width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Grid style={{ display: "flex", justifyContent: "space-between", padding: '10px 0px', width: '45%' }}>
                            <FormControl
                              component="div"
                              style={{ padding: "10px 0px", width: "45%" }}
                            >
                              <FormLabel component="legend">City</FormLabel>
                              <TextField
                                id="standard-select-currency-native"
                                select
                                onChange={props.handleChange}
                                SelectProps={{
                                  native: true,
                                  classes: { icon: "icon" },
                                }}
                                InputProps={{ className: "root" }}
                                variant="standard"
                                name="subCity"
                                value={props.values.subCity}
                              >
                                <option value={""}>Select from option</option>
                                {allSubCities &&
                                  allSubCities.map((city: any) => (
                                    <option value={city.name} key={city.name}>{city.name}</option>
                                  ))}
                              </TextField>
                            </FormControl>

                            <FormControl
                              component="div"
                              style={{ padding: "10px 0px", width: "45%" }}
                            >
                              <FormLabel component="legend">Postal Code</FormLabel>
                              <TextField
                                id="standard-select-role"
                                placeholder="Enter pin code"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                InputProps={{ className: "root" }}
                                name="subPostal"
                                value={props.values.subPostal}
                                variant="standard"
                              >
                              </TextField>
                            </FormControl>
                          </Grid>
                        </div>
                      </Box>
                      </>
                      }

                      <FormControl style={{ width: "100%", padding: "20px 0px" }}>
                        <FormLabel component="legend" className="label">
                          Event Venue(s){" "}<span className="astrick">*{" "}</span>
                          <span
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={this.handlePopoverOpen}
                            onMouseLeave={this.handlePopoverClose}
                            className="labelLink"
                          >
                            What is this?
                          </span>
                          <Popover
                            id="mouse-over-popover"
                            className="popover"
                            style={{ pointerEvents: 'none' }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            onClose={this.handlePopoverClose}
                            disableRestoreFocus
                          >
                            <Typography style={{ padding: "10px" }}>Information about field</Typography>
                          </Popover>
                        </FormLabel>
                        <TableContainer
                          component={Paper}
                          style={{ padding: "20px" }}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className="submissionsHeader">
                                  Venue Name
                                </TableCell>

                                <TableCell
                                  className="submissionsHeader"
                                  align="right"
                                >
                                  Action
                                </TableCell>
                              </TableRow>
                            </TableHead>
                           
                            <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                              <Droppable droppableId="droppable">
                                {(provided:any, snapshot:any) => (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver, customRow.length)}
                                  >
                                    {customRow.map((newRow: any, i: any) => {
                                      return (
                                        <Draggable key={newRow.id} draggableId={(newRow.id).toString()} index={i}>
                                        {(provided:any, snapshot:any) => (
                                          <TableBody
                                            ref={provided.innerRef}
                                           
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                        <TableRow className={`${(venueId === newRow.id && openVenue || (customRow.length > 1 && newRow.attributes.vanue_name === "")) && 'highlighted'} overrides`}>
                                          <TableCell className="fullWidth">
                                            <TextField
                                              name="vanue_name"
                                              value={newRow.attributes.vanue_name}
                                              id="outlined-disabled"
                                              placeholder="Venue Name"
                                              style={{ width: "90%" }}
                                              className="inputClass"
                                              InputProps={{ className: "root", onFocus: () => this.handleVenueEdit(newRow.id) }}
                                              onChange={event => this.handleChangeInput(newRow.id, event)}
                                            />
                                            {(venueId === newRow.id && openVenue || (customRow.length > 1 && newRow.attributes.vanue_name === "")) ?
                                              <Button className="EditCollapse" onClick={() => this.handleVenueCollapse(newRow.id)}>Collapse</Button>
                                              : <Button className="EditCollapse" onClick={() => this.handleVenueEdit(newRow.id)}> Edit</Button>}
                                            <span {...provided.draggableProps} ><img src={DragIcon} className={`${snapshot.isDragging ? "hide" : "show"} dragButton`}/></span>
                                          </TableCell>

                                          <TableCell align="right">
                                            <IconButton style={{ padding: '0px' }} onClick={() => this.deleteRow(newRow.id)}>
                                              <img style={{ width: "50px" }} src={Delete} alt="logo" />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                        {(venueId === newRow.id && openVenue || (customRow.length > 1 && newRow.attributes.vanue_name === "")) ?
                                          <TableRow className="expandedVenue">
                                            {newRow.attributes.online_event ? <td className="tableData">This is an Online event. Click on save to continue.</td> 
                                              : <>
                                                <td className="tableData">
                                                  <FormLabel component="legend">Address</FormLabel>
                                                  <TextField
                                                    name="address"
                                                    value={newRow.attributes.vanue_address.address}
                                                    id="outlined-disabled"
                                                    placeholder="Address"
                                                    className="inputClass fullWidth"
                                                    onChange={event => this.handleChangeInput(newRow.id, event)}
                                                  />
                                                </td>
                                                <td className="tableData fullWidthRow">

                                                  <FormControl component="div" className="halfWidth">
                                                    <FormLabel component="legend">Country of Origin</FormLabel>
                                                    <TextField
                                                      id="standard-select-currency-native"
                                                      select
                                                      SelectProps={{ native: true, classes: { icon: "icon" }, }}
                                                      InputProps={{ className: "root" }}
                                                      variant="standard"
                                                      name="country"
                                                      value={newRow.attributes.vanue_address.country}
                                                      onChange={event => this.handleCountryChange(newRow.id, event)}
                                                    >
                                                      <option value={""}>Select from option</option>
                                                      {countries &&
                                                        countries.map((cur: any) => (
                                                          <option value={cur.isoCode} key={cur.isoCode}>{cur.name}</option>
                                                        ))}
                                                    </TextField>
                                                  </FormControl>

                                                  <FormControl component="div" className="halfWidth">
                                                    <FormLabel component="legend">State / Province</FormLabel>
                                                    <TextField
                                                      id="standard-select-currency-native"
                                                      select
                                                      SelectProps={{ native: true, classes: { icon: "icon" }, }}
                                                      InputProps={{ className: "root" }}
                                                      variant="standard"
                                                      name="state"
                                                      value={newRow.attributes.vanue_address.state}
                                                      onChange={event => this.handleStateChange(newRow.id, event)}
                                                    >
                                                      <option value={""}>Select from option</option>
                                                      {venueStates &&
                                                        venueStates.map((state: any) => (
                                                          <option value={state.isoCode} key={state.isoCode}>{state.name}</option>
                                                        ))}
                                                    </TextField>
                                                  </FormControl>

                                                </td>
                                                <td className="tableData fullWidthRow" >
                                                  <FormControl component="div" className="halfWidth">
                                                    <FormLabel component="legend">City</FormLabel>
                                                    <TextField
                                                      id="standard-select-currency-native"
                                                      select
                                                      SelectProps={{ native: true, classes: { icon: "icon" }, }}
                                                      InputProps={{ className: "root" }}
                                                      variant="standard"
                                                      name="city"
                                                      value={newRow.attributes.vanue_address.city}
                                                      onChange={event => this.handleChangeInput(newRow.id, event)}
                                                    >
                                                      <option value={""}>Select from option</option>
                                                      {venueCities &&
                                                        venueCities.map((city: any) => (
                                                          <option value={city.name} key={city.name}>{city.name}</option>
                                                        ))}
                                                    </TextField>
                                                  </FormControl>

                                                  <FormControl component="div" className="halfWidth" >
                                                    <FormLabel component="legend">Postal Code</FormLabel>
                                                    <TextField
                                                      type="number"
                                                      id="standard-select-role"
                                                      placeholder="Enter pin code"
                                                      InputProps={{ className: "root" }}
                                                      name="postal_code"
                                                      value={newRow.attributes.vanue_address.postal_code}
                                                      variant="standard"
                                                      onChange={event => this.handleChangeInput(newRow.id, event)}
                                                    >
                                                    </TextField>
                                                  </FormControl>
                                                </td>
                                              </>
                                            }
                                            <td className="tableData">
                                              <FormControlLabel
                                                control={<CustomColorCheckbox />}
                                                checked={newRow.attributes.online_event}
                                                name="online_event"
                                                label={"Online event"}
                                                onChange={event => this.handleChangeInput(newRow.id, event)}
                                              />

                                              <Button className="saveVenueButton" onClick={() => this.handleVenueSave(newRow.id)}>Save venue</Button>
                                            </td>
                                          </TableRow>
                                          : ""}
                                        </TableBody>
                                        )}
                                      </Draggable>
                                      );
                                    })}
                                  <span style={{ display: "none"}}>{provided.placeholder}</span>
                               </div>
                              )}
                              </Droppable>
                            </DragDropContext>
                            {(addBtnVisible || customRow.length === 0) && <Button
                              className="submissionsButton"
                              onClick={() => this.addCustomFile()}
                            >
                              ADD ANOTHER VENUE
                            </Button>}
                          </Table>
                        </TableContainer>
                      </FormControl>

                    </Box>
                  </div>
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', width: '43%', flexDirection: 'row', pt: 2, marginBottom: "20px" }}>
              <Button
                color="inherit"
                onClick={this.props.handleBack}
                style={{ marginRight: "20px" }}
              >
                BACK
              </Button>
               {/* @ts-ignore */}
              <Button color="primary" type="submit" ref={this.callRef}>
                NEXT
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      </>
    );
  }
}
const CustomColorCheckbox = withStyles({
  root: {
    color: "#a5259f",
    "&$checked": {
      color: "#a5259f",
    },
  },
})((props) => <Checkbox color="default" {...props} />);
// Customizable Area End