import * as React from 'react';
// Customizable Area Start
import {
  Box,
  Link,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
// Customizable Area End

import { awardImage, dragDrop } from "./assets"
import PersonalDetails from "./personalDetails.web"
// Customizable Area Start
import moment from "moment";
// Customizable Area End

// Customizable Area Start
export interface accountPhotosType {
  id: number, url:string,
}
const MyProfile = ({
    filmographyData,
    accountData,
    handleUpdatePhotos,
    deleteAccountPhotos,
    loader
}: any) => {

    // Customizable Area Start
    const [files, setFiles] = React.useState<File[]>([]);
    const [inputKey, setInputKey] = React.useState(0);
    const [accountPhotos, setAccountPhotos] = React.useState<Array<accountPhotosType>>();

    React.useEffect(() => {
      if(accountData){
        setAccountPhotos(accountData.account_photo)
      }
    }, [accountData])

    const handleBiography = () => {
        window.location.replace('/Settings2');
    }

    const handleViewFilmography = () => {
        window.location.replace('/Dashboard');
    }

    const changeHandler = ({
      currentTarget: { files },
    }: React.ChangeEvent<HTMLInputElement>) => {
      if (files && files.length) {
        setFiles((existing) => existing.concat(Array.from(files)));
      }
      setInputKey((key) => key + 1);
      handleUpdatePhotos(files);
    };

    const handleDeleteImage = (id: any) => {
      deleteAccountPhotos(id)
    };


  return (
        <Box sx={webStyles.bottomRight}>
            <Box sx={webStyles.formCard}>
                <Box sx={{bgcolor: "#320b4c", padding: "10px", display: "flex", justifyContent: "space-between"}}>
                    <Typography variant="h4" style={{ color: "#FFF"}} >My Profile</Typography>
                    {accountPhotos ? (
                    <Typography
                      variant="h4"
                      style={{
                        color: "#FFF",
                        cursor: "pointer",
                        paddingRight: "20px",
                        position: "relative",
                      }}
                    >
                      Add Photos
                      <input
                        key={inputKey}
                        type="file"
                        onChange={changeHandler}
                        name="account_photo"
                        style={webStyles.addPhotos}
                        multiple
                        accept="image/*"
                      />
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
                {accountPhotos && accountPhotos.length > 0 && (
                <Box sx={{ padding: "20px" }}>
                  <Box
                    style={{
                      position: "relative",
                      height: `${Math.ceil(accountPhotos.length / 3) * 200}px`,
                      border: "2px solid #ccc",
                      borderRadius: "10px",
                    }}
                  >
                     {loader && <Box sx={webStyles.dragZonOverlay}>
                        <Box sx={webStyles.dragZonLoader}>
                            <CircularProgress />
                        </Box>
                    </Box> }
                    <div
                      style={{
                        width: "100%",
                        height: `${Math.ceil(accountPhotos.length / 3) * 200}px`,
                        position: "absolute",
                        top: "0",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      {accountPhotos.map((file, index) => {
                        let width = accountPhotos.length <= 3
                        ? `${100 / accountPhotos.length}%`
                        : "33.3%";
                        return (
                          <>
                            <div
                              key={index}
                              style={{
                                flex: "0 0 auto",
                                width: width,
                                position: "relative",
                              }}
                            >
                              <span
                                style={webStyles.closeButton}
                                onClick={() => handleDeleteImage(file.id)}
                              >
                                X
                              </span>
                              <img
                                src={file.url}
                                style={webStyles.imageContainer}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </Box>
                </Box>
              ) }
              {!accountPhotos && <Box style={{ background: "white" }}>
                      <Box sx={webStyles.dragzon}>
                      {loader && <Box sx={webStyles.dragZonOverlay}>
                        <Box sx={webStyles.dragZonLoader}>
                            <CircularProgress />
                        </Box>
                      </Box> }
                        <input
                          key={inputKey}
                          type="file"
                          onChange={changeHandler}
                          name="account_photo"
                          multiple
                          accept="image/*"
                          style={{
                            opacity: 0,
                            width: "100%",
                            height: "200px",
                            cursor: "pointer",
                            position: "absolute",
                            zIndex: 1,
                            top: 0,
                            left: 0,
                          }}
                        />
                          <Box sx={webStyles.dragzonCard}>
                              <img src={dragDrop} width={40} style={{marginBottom: "10px"}} />
                              <Typography variant="body2" style={webStyles.dragNDrop} >Drag & Drop image/s to upload</Typography>
                          </Box>
                      </Box>
                  </Box>
                
              }
            </Box>
      {accountData && accountData.bio ? (
        <Box sx={{ padding: "20px" }}>{accountData.bio}</Box>
      ) : (
        <Button
          variant="outlined"
          style={{
            border: "1px solid #A5259F",
            color: "#A5259F",
            marginBottom: "30px",
            fontSize: "18px",
            fontWeight: 600,
          }}
          onClick={handleBiography}
        >
          + ADD BIOGRAPHY
        </Button>
      )}
      <Box sx={webStyles.formCard}>
                <Box sx={{bgcolor: "#320b4c", padding: "10px", display: "flex", justifyContent: "space-between"}}>
                    <Typography variant="h4" style={{ color: "#FFF"}} >Filmography</Typography>
                    <Typography variant="h4" style={{ color: "#FFF", cursor: 'pointer', paddingRight: "20px"}} onClick={handleViewFilmography} >View all</Typography>
                </Box>
                <Box sx={{padding: "20px"}}>
                {filmographyData ?
                  (filmographyData.length > 0 ? filmographyData.map((project: any) =>
                  {
                    if(project.visibilty){
                     return <Box sx={webStyles.filmCard} key={project.id}>
                            <img src={project.project_poster ? project.project_poster : awardImage} style={{ height: "100px", width: "100px", objectFit: "cover"}} />  
                            <Box >
                                <Typography variant="h4" style={{ fontWeight: 600, borderBottom: "1px solid #ccc", marginBottom: "5px", }} > {project.name}</Typography>
                                <Typography variant="body2" style={{ color: "#333",width: "288px" }} >
                                    {project.description ? project.description : "Project with credits that match your accounts name exactly will appear here."}
                                </Typography>
                            </Box>
                      </Box>
                    }
                  }
                  ): <Typography>No data available</Typography>)
                  : <Typography>Loading ...</Typography>} 
                  </Box>
            </Box>
        {accountData && accountData.dob ||
        accountData.nickname ||
        accountData.birth_city ||
        accountData.current_city ||
        accountData.hometown ||
        accountData.height ||
        accountData.gender ||
        accountData.ethnicity ||
        accountData.eye_color ||
        accountData.zodiac_sign ||
        accountData.married_to ||
        accountData.children ? (
            <>
            <Box sx={{ bgcolor: "#320b4c", padding: "10px" }}>
                <Typography variant="h4" style={{ color: "#FFF" }}>
                Personal Details
                </Typography>
            </Box>
            <Box sx={{ padding: "20px" }} style={{ background: "white" }}>
                {accountData.dob && (
                <div>
                    <span style={{ fontWeight: "bold" }}>Birthdate :</span>{" "}
                    {moment(accountData.dob).format("DD-MM-YYYY")}{" "}
                </div>
                )}
                {accountData.nickname && (
                <div>
                    <span style={{ fontWeight: "bold" }}>Nickname : </span>
                    {accountData.nickname}{" "}
                </div>
                )}
                {accountData.birth_city && (
                <div>
                    <span style={{ fontWeight: "bold" }}>Birth City :</span>{" "}
                    {accountData.birth_city}{" "}
                </div>
                )}
                {accountData.current_city && (
                <div>
                    <span style={{ fontWeight: "bold" }}>Current City : </span>
                    {accountData.current_city}{" "}
                </div>
                )}
                {accountData.hometown && (
                <div>
                    <span style={{ fontWeight: "bold" }}>Hometown : </span>
                    {accountData.hometown}{" "}
                </div>
                )}
                {accountData.height && (
                <div>
                    <span style={{ fontWeight: "bold" }}>Height :</span>{" "}
                    {accountData.height}{" "}
                </div>
                )}
                {accountData.gender && (
                <div>
                    <span style={{ fontWeight: "bold" }}>Gender :</span>{" "}
                    {accountData.gender}{" "}
                </div>
                )}
                {accountData.ethnicity && (
                <div>
                    <span style={{ fontWeight: "bold" }}>Ethnicity : </span>
                    {accountData.ethnicity}{" "}
                </div>
                )}
                {accountData.eye_color && (
                <div>
                    <span style={{ fontWeight: "bold" }}>Eye Color :</span>{" "}
                    {accountData.eye_color}{" "}
                </div>
                )}
                {accountData.zodiac_sign && (
                <div>
                    <span style={{ fontWeight: "bold" }}>Zodiac Sign : </span>
                    {accountData.zodiac_sign}{" "}
                </div>
                )}
                {accountData.married_to && (
                <div>
                    <span style={{ fontWeight: "bold" }}>Married To :</span>{" "}
                    {accountData.married_to}{" "}
                </div>
                )}
                {accountData.children ? (
                <div>
                    <span style={{ fontWeight: "bold" }}>Children :</span>{" "}
                    {accountData.children}{" "}
                </div>
                ) : (
                ""
                )}
            </Box>
            </>
        ) : (
        <Button
          variant="outlined"
          style={{
            border: "1px solid #A5259F",
            color: "#A5259F",
            marginBottom: "30px",
            fontSize: "18px",
            fontWeight: 600,
          }}
          onClick={handleBiography}
        >
          + ADD PERSONAL DETAILS
        </Button>
      )}
            <Box >
                <Button variant="contained" style={{ background: "#A5259F", color: "#fff", marginTop: "20px"}} type="submit">Save Changes</Button>
            </Box>
        </Box>
  );
  // Customizable Area End
};
export default MyProfile;

const webStyles = {
    main: {
        display: "flex",
        justifyContent: "center",
        height: "100px",
        background: "000"
    },
    bottomRight: {
        display: "flex",
        flexDirection: "column",
        width: '75%'
    },
    formCard: {
        background: "#fff",
        marginBottom: "20px"
    },
    dragzon: {
        height: "200px",
        border: "2px solid #ccc",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "30px 50px",
        position: "relative" as const,
    },
    dragZonOverlay: {
        position: "absolute",
        width: "100%",
        height: "100%",
        background:" rgba(0,0,0,0.2)",
        zIndex: 3
    },
    dragZonLoader: {
        top: "50%",
        left:" 50%",
        transform: "translate(-50%, -50%)",
        position: "absolute"
    },
    dragzonCard: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    filmCard: {
        width: "50%",
        display: "flex",
        padding: "10px",
        marginBottom: "20px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        boxShadow: "2px 2px 6px 5px #eee",
        justifyContent: "space-between",
        gap: "15px"
    },
    dragNDrop: {
        color: "#4684EB", textDecoration: "underline", fontWeight: 600
    },
    addPhotos: {
      position: "absolute" as const,
      opacity: 0,
      top: "-9px",
      left: "0",
      height: "53px",
      width: "120px",
      cursor: "pointer",
  },
  imageContainer: {
      width: "-webkit-fill-available",
      objectFit: "cover" as const,
      height: "200px",
      position: "absolute" as const,
      border: "3px solid white",
      borderRadius: "10px",
  },
  closeButton: {
      position: "absolute" as const,
      top: "5px",
      right: "5px",
      background: "#fff",
      borderRadius: "50%",
      padding: "0 6px",
      zIndex: 2 as const,
      cursor: "pointer",
  },
  dragzonImage: {
      position: "relative" as const,
      height: "200px",
      border: "2px solid #ccc",
      borderRadius: "10px",
  },
}
// Customizable Area End