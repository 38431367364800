// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  formik?: any;
  handleNextStep?: any;
  callFestivalAPI?: any;
  handleLoadingFalse?: any;
  handleLoadingTrue? : any;
  loading?: any;
}

interface S {
  currencyData: any;
  countryOrigin: string;
  allCities: any;
  allStates: any;
  activeStep: number;
  checkboxError: boolean;
  imageError?: boolean;
  customRow: any;
  checkedValues: any;
  file: any;
  previewImage: any;
  anchorEl: any;
  festivalData: any;
  festivalID: any;
  isReinitailize: boolean;
}

interface SS {
  id: any;
}

export interface organiserType {
   name:string, title:string, _destroy?: boolean, id?: number
}

export default class CoverImageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCurrencyDataAPICall: string = "";
  getFestivalAPICall: string = '';
  updateEventAPICall: string = '';
  editEventCall:string = '';

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.PostDetailDataMessage)
    ]

    this.state = {
      currencyData: null,
      countryOrigin: "",
      allCities: new Array(),
      allStates: new Array(),
      activeStep: 0,
      checkboxError: false,
      imageError: false,
      customRow: [{ id: Math.random(), name: "", title: "", _destroy: false }],
      checkedValues: [],
      file: "",
      previewImage: "",
      anchorEl: null,
      festivalData: null,
      festivalID: null,
      isReinitailize: true
    };
    // @ts-ignore
    this.callRef = React.createRef(); 
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    const url = window.location.href.split("/")[4]
    if(url !== "add" || this.props.callFestivalAPI){
      this.getFestivalData()
    }
    const id = window.location.href.split("/")[5]
    if(id){
      this.setState({ festivalID: id})
    }
    this.setState({ imageError: false, checkboxError: false })
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    const { checkedValues } = this.state;

    if (!(checkedValues.length === prevState.checkedValues.length && checkedValues.every((a1: any) => prevState.checkedValues.includes(a1)))) {
      if (checkedValues?.length < 1) {
        this.setState({ checkboxError: true })
      } else {
        this.setState({ checkboxError: false })
      }
    }
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.PostDetailDataMessage) === message.id){
      if(message.properties.text === "SAVE_FESTIVAL_FORM_DATA"){
          //@ts-ignore
          this.callRef?.current.click();
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getCurrencyDataAPICall) {
        this.setState({
          currencyData: responseJson.data
        })
      }
      
      if (apiRequestCallId === this.getFestivalAPICall) {
        const { festivalID } = this.state;
        this.props.handleLoadingFalse();
        responseJson && responseJson.data.map((rank: any, i: any, row: any) => {
          const myArray = rank.attributes.event_type?.trim().split(",") || []
          if(festivalID){
            if (rank.id === festivalID) {
              this.setState({
                festivalData: rank.attributes,
                previewImage: rank.attributes.image_file,
                checkedValues: myArray,
                customRow: rank.attributes.event_organizers
              })
            }
          } else {
            if (i + 1 === row.length) {
              this.setState({
                festivalData: rank.attributes,
                festivalID: rank.id,
                previewImage: rank.attributes.image_file,
                checkedValues: myArray,
                customRow: rank.attributes.event_organizers
              })
            }
          }
          setTimeout(() => {this.setState({isReinitailize: false})}, 2000);
        })
        }
      

      if (apiRequestCallId === this.editEventCall || apiRequestCallId === this.updateEventAPICall) {
        this.props.handleLoadingFalse();
        if(responseJson && !responseJson.errors) {
          toast.success("Congratulations, Your changes have been saved")
          this.props.handleNextStep();
          this.setState({
            festivalID: null,
          })
        } else {
          toast.error(`Error, ${responseJson.errors[0]}`)
        }
      }
    }
  }

  handleSubmitEvent = (values: any, customRow: any, file: any, eventType: any) => {
    let newEventType = eventType.toString().charAt(0) === "," ? eventType.toString().substring(1) : eventType;
    var formData = new FormData();
    formData.append('event[title]', values.eventName);
    formData.append("event[event_description]", values.description);
    formData.append("image_file", file);
    formData.append('event[award_prize]', values.awards);
    formData.append("event[audience_attendance]", values.keyStats);
    formData.append('event[estimated_submissions]', values.submissions);
    formData.append("event[projects_selected]", values.project);
    formData.append('event[awards_presented]', values.awardsPresented);
    formData.append('event[years_running]', values.years_running);
    formData.append('event[event_type]', newEventType);
    customRow.forEach((element: any, id: any) => {
      if(element.id < 1 && !element._destroy) {
        formData.append(`event[event_organizers_attributes]${[id]}[name]`, element.name);
        formData.append(`event[event_organizers_attributes]${[id]}[title]`, element.title);
      } else if (element.id > 1) {
        formData.append(`event[event_organizers_attributes]${[id]}[name]`, element.name);
        formData.append(`event[event_organizers_attributes]${[id]}[title]`, element.title);
        formData.append(`event[event_organizers_attributes]${[id]}[id]`, element.id);
        formData.append(`event[event_organizers_attributes]${[id]}[_destroy]`, element._destroy ? element._destroy : false);
      }
    })
    if(this.state.festivalID){
      this.updateEventDetails(formData, this.state.festivalID)
    } else {
      this.editEventData(formData)
    } 
    this.props.handleLoadingTrue(); 
  }

  editEventData(values: any) {
    const token = localStorage.getItem('token')
    const header = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editEventCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editEventEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      values
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateEventDetails = (UpdatedData: any, festivalID: any) => {
    let url = `${configJSON.endPointApiUpdateEventDetails + festivalID}`
    
    const headers = {
      "Content-Type": configJSON.APiContentType,
      token:localStorage.getItem('authToken'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateEventAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      UpdatedData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFestivalData(): boolean {
    this.props.handleLoadingTrue();
    const header = {
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFestivalAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiFestivalData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }

  uploadLogoHandler = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      this.setState({ file: event.target.files[0], previewImage: URL.createObjectURL(event.target.files[0]), imageError: false })
    }
  }

  handleSubmit = () => {
    const { checkedValues, file, previewImage } = this.state;
    if (checkedValues.length < 1) {
      this.setState({ checkboxError: true })
    }
    if (!file && !previewImage) {
      this.setState({ imageError: true })
    }
  }

  addCustomFile = () => {
    this.setState({ customRow: [...this.state.customRow, { id: Math.random(), name: "", title: "",_destroy: false }] });
  };

  deleteRow = (index: any) => {
    const values = [...this.state.customRow];
    const finalValues = values.map((value) => (value.id === index || (value.hasOwnProperty('id') && value.id === index)) ?  {...value, "_destroy": true} :  value)
    this.setState({customRow: finalValues});
  };

  handleChangeInput = (id: any, event: any) => {
    const newInputFields = this.state.customRow.map((i: any) => {
      if (id === i.id) {
        i[event.target.name as any] = event.target.value
      }
      return i;
    })
    this.setState({ customRow: newInputFields });
  }

  handleSelect = (checkedName: any) => {
    const newNames = this.state.checkedValues?.includes(checkedName)
      ? this.state.checkedValues?.filter((name: any) => name !== checkedName)
      : [...(this.state.checkedValues ?? []), checkedName];
    this.setState({ checkedValues: newNames });
    return newNames;
  }

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  handlePopoverOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };
}
 // Customizable Area End
