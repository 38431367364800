// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
//@ts-ignore
import Data from './countries.json';

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  formik?: any;
  handleNextStep?: any;
  handleBack?: any;
  handleLoadingFalse?: any;
  handleLoadingTrue? : any;
  loading?: any;
}

interface S {
  currencyData: any;
  customRow: any;
  anchorEl: any;
  festivalData: any;
  festivalID: any;
  isReinitailize: boolean;
  checkboxError: boolean;
  currentCurrency: any;
  openModal: any;
}

interface SS {
  id: any;
}

export default class CoverImageController extends BlockComponent<
  Props,
  S,
  SS
> {
  editEventCall: any;
  getCurrencyDataAPICall: string = "";
  labelTitle: string = "";
  updateEventAPICall: string = '';
  getFestivalAPICall: string = '';

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.PostDetailDataMessage)
    ]

    this.state = {
      currencyData: null,
      customRow: [{ id: Math.random(), category_name: "", disabled: true}],
      anchorEl: null,
      isReinitailize: true,
      festivalData: null,
      festivalID: null,
      checkboxError: false,
      currentCurrency: null,
      openModal: null,
    };
    // @ts-ignore
    this.callRef = React.createRef(); 
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // this.getCurrencyData();
    this.getFestivalData();
    const id = window.location.href.split("/")[5]
    if(id){
      this.setState({ festivalID: id})
    }
    this.setState({ currencyData: Data})
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    const { currentCurrency, openModal } = this.state;
    if (currentCurrency !== prevState.currentCurrency)  {
      this.setState({
        openModal: {
          ...openModal,
          cur: currentCurrency?.countryCode,
          prevCur: prevState?.currentCurrency?.countryCode || "IN"
        }
      })
    }
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.PostDetailDataMessage) === message.id){
      if(message.properties.text === "SAVE_FESTIVAL_FORM_DATA"){
          //@ts-ignore
          this.callRef?.current.click();
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      // if (apiRequestCallId === this.getCurrencyDataAPICall) {
      //   if (responseJson != null && !responseJson.error) {
      //     this.setState({
      //       currencyData: responseJson.data
      //     })
      //     this.getFestivalData();
      //   } else {
      //     toast.error(responseJson.error)
      //   }
      // }

      if (apiRequestCallId === this.getFestivalAPICall) {
        const { festivalID, currencyData} = this.state;
        this.props.handleLoadingFalse();
        responseJson && responseJson.data.map((rank: any, i: any, row: any) => {
          let list = currencyData.countries.country?.filter((cur: any) => {
            if(rank.id === festivalID){
              return cur.countryCode === (rank.attributes.accept_submission?.currency || "IN")
            }
          })
          if(festivalID){
            if (rank.id === festivalID) {
              this.setState({
                festivalData: rank.attributes.accept_submission,
                currentCurrency: list[0],
                customRow: rank.attributes.category_names || [{ id: Math.random(), category_name: "", disabled: false}], 
              })
            }
          } else {
            if (i + 1 === row.length) {
              this.setState({
                festivalData: rank.attributes.accept_submission,
                festivalID: rank.id,
                currentCurrency: list[0],
                customRow: rank.attributes.category_names || [{ id: Math.random(), category_name: "", disabled: false}], 
              })
            }
          }
          setTimeout(() => { this.setState({ isReinitailize: false }) }, 2000);
        })
      }

      if (apiRequestCallId === this.updateEventAPICall) {
        this.props.handleLoadingFalse();
        if (responseJson != null && !responseJson.error) {
          toast.success("Congratulations, Your changes have been saved")
        } else {
          toast.error(responseJson.error)
        }
      }
    }
  }

  handleSubmitEvent = (values: any, customRow: any, currency: any) => {
    var formData = new FormData();
    formData.append('event[accept_submission_attributes][currency]', currency);
    customRow.forEach((element: any, id: any) => {
      formData.append(`event[accept_submission_attributes][category_names_attributes]${[id]}[category_name]`, element.category_name);
    })
    this.updateEventDetails(formData,  this.state.festivalID)
    this.props.handleLoadingTrue();
  }

  updateEventDetails = (UpdatedData: any, festivalID: any) => {
    let url = `${configJSON.endPointApiUpdateEventDetails + festivalID}`
    
    const headers = {
      "Content-Type": configJSON.APiContentType,
      token:localStorage.getItem('authToken'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateEventAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      UpdatedData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFestivalData(): boolean {
    this.props.handleLoadingTrue();
    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFestivalAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiFestivalData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }

  // getCurrencyData(): boolean {
  //   const header = {
  //     token: localStorage.getItem('token')
  //   };
  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );

  //   this.getCurrencyDataAPICall = requestMessage.messageId;
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     configJSON.endPointApiGetCurrency
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.methodTypeApiGetUserProfile
  //   );
  //   runEngine.sendMessage(requestMessage.id, requestMessage);

  //   return true;
  // }
  
  addCustomFile = () => {
    this.setState({ customRow: [...this.state.customRow, { id: Math.random(), category_name: "",  disabled: false}] });
  };

  deleteRow = (index: any) => {
    const values = [...this.state.customRow];
    values.splice(values.findIndex(value => value.id === index), 1);
    this.setState({ customRow: values });
  };

  editRow = (rowId: any) => {
    const selectedInput = this.state.customRow.map((item: any, index: number) => {
      if (rowId === item.id) {
        item["disabled"] = false
      } else if (item.hasOwnProperty("created_at")){
        item["disabled"] = true
      } else {
        item["disabled"] = true
      }
      return item;
    })

    this.setState({customRow: selectedInput})
  }

  handleChangeInput = (id: any, event: any) => {
    const newInputFields = this.state.customRow.map((i: any) => {
      if (id === i.id) {
        i[event.target.name as any] = event.target.value
      }
      return i;
    })
    this.setState({ customRow: newInputFields });
  }
  
  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  handlePopoverOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCurrencyChange = (e: any) => {
    const { currencyData, openModal } = this.state;
    this.setState({
      openModal: {
        ...openModal,
        open: true,
      }
    })
    let list = currencyData.countries.country?.filter((cur: any) => cur.countryCode === e.target.value)
    list && this.setState({ currentCurrency: list[0] })
   
  }

  handleClose = () => {
    const { openModal, currentCurrency, currencyData} = this.state;
    let list = currencyData.countries.country?.filter((cur: any) => cur.countryCode === openModal.prevCur)
    list && this.setState({ currentCurrency: list[0] })

    this.setState({
      openModal: {
        ...openModal,
        open: false,
      }
    })
  };

  handleChangeCurrency = () => {
    const { openModal } = this.state;
    this.setState({
      openModal: {
        ...openModal,
        open: false,
      }
    })
  }
}
 // Customizable Area End
